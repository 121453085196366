import { HTTP } from "@/components/http-common";

export default {
	state: {
		client: {},
		clients: [],
		sortAscending: true,
		sortBy: "name",
		showArchived: false
	},
	mutations: {
		setClient(state, client) {
			state.client = client;
		},
		loadClients(state, clients) {
			state.clients = clients;
		},
		createClient(state, payload) {
			state.clients.push(payload);
		},
		updateClient(state, payload) {
			let index = state.clients.findIndex(client => client._id == payload);
			let client = state.clients[index];
			client.name = payload.name;
			client.status = payload.status;
		},
		deleteClient(state, payload) {
			let index = state.clients.findIndex(client => client._id == payload);
			state.clients.splice(index, 1);
		},
		toggleArchivedClients(state){
			state.showArchived = !state.showArchived;
		}
	},
	actions: {
		toggleArchivedClients({commit}){
			commit('toggleArchivedClients');
		},
		loadClients({ commit, dispatch, getters }) {
			commit("setLoading", true);
			HTTP.get("/clients", {
				headers: {
					Authorization: "Bearer " + getters.user.token
				}
			})
				.then(response => {
					commit("loadClients", response.data.clients);
					commit("setLoading", false);
				})
				.catch(error => {
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		createClient({ commit, dispatch, getters }, payload) {
			// eslint-disable-next-line no-console
			console.log(payload);
			commit("setLoading", true);
			HTTP.post("/clients", payload, {
				headers: {
					Authorization: "Bearer " + getters.user.token
				}
			})
				.then(response => {
					// eslint-disable-next-line no-console
					console.log(response);
					dispatch("setResponse", response);
					dispatch("loadClients");
					commit("setLoading", false);
				})
				.catch(error => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		updateClient({ commit, dispatch, getters }, payload) {
			commit("setLoading", true);
			HTTP.patch("/clients/" + payload.id, payload, {
				headers: {
					Authorization: "Bearer " + getters.user.token
				}
			})
				.then(response => {
					dispatch("setResponse", response);
					commit("setLoading", false);
					dispatch("loadClients");
				})
				.catch(error => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		deleteClient({ commit, dispatch, getters }, id) {
			commit("setLoading", true);
			HTTP.delete("/clients/" + id, {
				headers: {
					Authorization: "Bearer " + getters.user.token
				}
			})
				.then(response => {
					dispatch("setResponse", response);
					commit("setLoading", false);
					dispatch("loadClients");
				})
				.catch(error => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error);
					commit("setLoading", false);
				});
		}
	},
	getters: {
		client: state => {
			return state.client;
		},
		sortedClients(state) {
			const items = [...state.clients].sort((a, b) => {
				if (a[state.sortBy].toLowerCase() > b[state.sortBy].toLowerCase()) {
					return 1;
				}
				if (a[state.sortBy].toLowerCase() < b[state.sortBy].toLowerCase()) {
					return -1;
				}
				return 0;
			});

			if (!state.sortAscending) {
				items.reverse();
			}
			if (!state.showArchived) {
				return items.filter(item => {
					return item.status !== 'archived';
				});
			} else {
				return items;
			}
		}
	}
};
