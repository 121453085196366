import { HTTP, HTTP_SIMPLE } from "@/components/http-common";
import jwt from "jsonwebtoken";
import router from "@/router/index";
import { Promise } from "core-js";

export default {
	state: {
		user: {},
		users: [],
	},
	mutations: {
		setUser(state, user) {
			state.user = user;
		},
		loadUsers(state, users) {
			state.users = users;
		},
		deleteUser(state, payload) {
			let objToDelete = state.users.findIndex((user) => user._id == payload);
			state.users.splice(objToDelete, 1);
		},
	},
	actions: {
		async autoSignIn({ dispatch }) {
			try {
				dispatch("setLoading", true);
				// eslint-disable-next-line no-console
                console.log("autoSignIn");
                await dispatch("tokenRefresh");
				dispatch("sidebar", true);
				dispatch("loadPreferences");
				dispatch("setLoading", false);
				router.push("/");
			} catch (error) {
				// fail: set error message, clear user everywhere, redirect to login screen
				dispatch("setError", error.response);
				dispatch("signOut");
				dispatch("setLoading", false);
				dispatch("sidebar", false);
				router.push("/");
			}
		},
		tokenRefresh({ commit, dispatch }) {
			return new Promise((resolve, reject) => {
				// eslint-disable-next-line no-console
				console.log("tokenRefresh");
				// grab refresh token cookie
				let payload = {
					refreshToken: window.$cookies.get("refreshToken"),
				};
				// send to api endpoint
				HTTP_SIMPLE.post("/users/token", payload)
					.then((response) => {
						// success:   set user.token in state, reset user cookie, reset refreshToken cookie -- and send resolve reponse
						let decoded = jwt.decode(response.data.token);
						let user = {
							id: decoded.id,
							token: response.data.token,
							email: decoded.email,
							role: decoded.role,
						};
						commit("setUser", user);
						window.$cookies.set("user", user, "15m");
						window.$cookies.set(
							"refreshToken",
							response.data.refreshToken,
							"7d"
						);
						resolve(response);
					})
					.catch((error) => {
						// fail: set error message, clear user everywhere, redirect to login screen -- and reject promise
						dispatch("setError", error.response);
						reject(error);
					});
			});
		},
		forgotPassword({ commit, dispatch }, payload) {
			commit("setLoading", true);
			HTTP.post("/users/forgot", {
				email: payload.email,
			})
				.then((response) => {
					dispatch("setResponse", response);
					commit("setLoading", false);
				})
				.catch((error) => {
					dispatch("setError", error.response);
					commit("setLoading", false);
				});
		},
		resetPassword({ commit, dispatch }, payload) {
			commit("setLoading", true);
			HTTP.post("/users/reset", payload)
				.then((response) => {
					dispatch("setResponse", response);
					commit("setLoading", false);
				})
				.catch((error) => {
					dispatch("setError", error.response);
					commit("setLoading", false);
				});
		},
		signUp({ commit, dispatch }, payload) {
			commit("setLoading", true);
			HTTP.post("/users/signup", {
				email: payload.email,
				password: payload.password,
			})
				.then((response) => {
					dispatch("setResponse", response);
					commit("setLoading", false);
				})
				.catch((error) => {
					commit("setLoading", false);
					dispatch("setError", error.response);
				});
		},
		signIn({ commit, dispatch }, payload) {
			commit("setLoading", true);
			HTTP.post("/users/login", {
				email: payload.email,
				password: payload.password,
			})
				.then((response) => {
					let decoded = jwt.decode(response.data.token);
					let user = {
						id: decoded.id,
						token: response.data.token,
						email: decoded.email,
						role: decoded.role,
					};
					commit("setUser", user);
					window.$cookies.set("user", user, "15m");
					window.$cookies.set("refreshToken", response.data.refreshToken, "7d");
					dispatch("loadPreferences");
					dispatch("sidebar", true);
					commit("setLoading", false);
					dispatch("setResponse", response);
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.log(error);
					commit("setLoading", false);
					dispatch("setError", error.response);
					commit("setUser", {});
				});
		},
		async signOut({ commit, dispatch, getters }) {
			try {
				let response = await HTTP.post(
					"/users/logout",
					{},
					{
						headers: {
							Authorization: "Bearer " + getters.user.token,
						},
					}
				);
				commit("setUser", {});
				commit("loadUsers", []);
				commit("loadProjects", []);
				commit("loadPreferences", {});
				dispatch("sidebar", false);
				window.$cookies.set("user", {}, "0");
				window.$cookies.set("refreshToken", {}, "0");
				dispatch("setResponse", response);
				// add push router to login screen
			} catch (error) {
				dispatch("setError", error);
				// eslint-disable-next-line no-console
				console.log(error);
			}
		},
		loadUsers({ commit, dispatch, getters }) {
			commit("setLoading", true);
			HTTP.get("/users", {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					commit("loadUsers", response.data.users);
					commit("setLoading", false);
				})
				.catch((error) => {
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		deleteUser({ commit, dispatch, getters }, id) {
			commit("setLoading", true);
			HTTP.delete("/users/" + id, {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					dispatch("setResponse", response);
					commit("setLoading", false);
					commit("deleteUser", id);
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
	},
	getters: {
		user: (state) => {
			return state.user;
		},
	},
};
