import { HTTP } from "@/components/http-common";

export default {
	state: {
		editing: false,
		expense: {},
		expenses: [],
		report: [],
		pagination: {
			paginated: true,
			page: 1,
			limit: 20,
			count: 0,
		},
	},
	mutations: {
		setPage(state, page) {
			state.pagination.page = page;
		},
		setCount(state, count) {
			state.pagination.count = count;
		},
		setEditing(state, payload) {
			state.editing = payload;
		},
		setExpense(state, expense) {
			state.expense = expense;
		},
		setExpenses(state, expenses) {
			state.expenses = expenses;
		},
		setOutstandingExpenses(state, expenses) {
			state.outstandingExpenses = expenses;
		},
		loadReport(state, expenses) {
			state.report = expenses;
		},
		createExpense(state, payload) {
			state.expenses.push(payload);
		},
		changeDate(state, payload) {
			state.expense.date = payload;
		},
	},
	actions: {
		setEditing({ commit }, payload) {
			commit("setEditing", payload);
		},
		changeDate({ commit }, payload) {
			commit("changeDate", payload);
		},
		loadExpense({ commit, dispatch, getters }, id) {
			commit("setLoading", true);
			HTTP.get("/expenses/" + id, {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					commit("setExpense", response.data.expense);
					commit("setLoading", false);
				})
				.catch((error) => {
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		clearExpense({ commit }) {
			commit("setExpense", {});
		},
		loadExpenses({ commit, dispatch, getters, state }) {
			commit("setLoading", true);
			HTTP.get(
				"/expenses" +
					"?page=" +
					state.pagination.page +
					"&limit=" +
					state.pagination.limit,
				{
					headers: {
						Authorization: "Bearer " + getters.user.token,
					},
				}
			)
				.then((response) => {
					commit("setExpenses", response.data.expenses);
					commit("setCount", response.data.count);
					commit("setLoading", false);
				})
				.catch((error) => {
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		loadOutstandingExpenses({ commit, dispatch, getters }) {
			commit("setLoading", true);
			HTTP.get("/expenses?status=sent", {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					commit("setOutstandingExpenses", response.data.expenses);
					commit("setLoading", false);
				})
				.catch((error) => {
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		loadExpenseReport({ commit, dispatch, getters }, payload) {
			commit("setLoading", true);
			HTTP.get(
				"/expenses" + "?start=" + payload.start + "&end=" + payload.end,
				{
					headers: {
						Authorization: "Bearer " + getters.user.token,
					},
				}
			)
				.then((response) => {
					commit("loadReport", response.data.expenses);
					commit("setLoading", false);
				})
				.catch((error) => {
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		createExpense({ commit, dispatch, getters }, payload) {
			// eslint-disable-next-line no-console
			console.log(payload);
			commit("setLoading", true);
			HTTP.post("/expenses", payload, {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					// eslint-disable-next-line no-console
					console.log(response);
					dispatch("setResponse", response);
					dispatch("loadExpenses");
					commit("setLoading", false);
					commit("setEditing", true);
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		updateExpense({ commit, dispatch, getters }, payload) {
			commit("setLoading", true);
			let updateInfo = { ...payload, user: getters.user._id };

			HTTP.patch("/expenses/" + payload._id, updateInfo, {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					dispatch("setResponse", response);
					commit("setLoading", false);
					commit("setExpense", response.data.updatedExpense);
					dispatch("loadExpenses");
					dispatch("loadOutstandingExpenses");
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		deleteExpense({ commit, dispatch, getters }, id) {
			commit("setLoading", true);
			HTTP.delete("/expenses/" + id, {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					dispatch("setResponse", response);
					commit("setLoading", false);
					dispatch("loadExpenses");
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
	},
	getters: {
		editing: (state) => {
			return state.editing;
		},
		expense: (state) => {
			return state.expense;
		},
		sortedExpenses(state) {
			const items = [...state.expenses];
			// return items.reverse();
			return items;
		},
		sortedExpenseReport(state) {
			return state.report;
		},
		expensesNumPages(state) {
			return Math.ceil(state.pagination.count / state.pagination.limit);
		},
	},
};
