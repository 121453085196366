import { HTTP } from "@/components/http-common";

export default {
	state: {
		preferences: {}
	},
	mutations: {
		loadPreferences(state, preferences) {
			state.preferences = preferences;
		},
	},
	actions: {
		loadPreferences({ commit, dispatch, getters }) {
			commit("setLoading", true);
			HTTP.get("/preferences", {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					commit("loadPreferences", response.data.preferences);
					commit("setLoading", false);
				})
				.catch((error) => {
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		updatePreferences({ commit, dispatch, getters }, payload) {
			commit("setLoading", true);
			HTTP.patch("/preferences/", payload, {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
                    dispatch("setResponse", response);
                    dispatch("loadPreferences")
					commit("setLoading", false);
					// dispatch("loadPreferences");
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
	},
	getters: {
		preferences: (state) => {
			return state.preferences;
		}
	},
};
