import { HTTP } from "@/components/http-common";
import { DateTime } from "luxon";

export default {
	state: {
		time: {},
		times: [],
		report: [],
		pagination: {
			paginated: true,
			page: 1,
			limit: 20,
			count: 0,
		},
		tracker: {
			start: null,
			clockStart: DateTime.local(),
			end: null,
			snapShot: DateTime.local(),
			tick: null,
			clockActive: false,
			description: "",
			project: null,
			task: null,
		},
	},
	mutations: {
		setTick(state, payload) {
			state.tracker.tick = payload;
		},
		setClockActive(state, payload) {
			state.tracker.clockActive = payload;
		},
		setSnapShot(state, payload) {
			state.tracker.snapShot = payload;
		},
		setClockStart(state, payload) {
			state.tracker.clockStart = payload;
		},
		setStart(state, payload) {
			state.tracker.start = payload;
		},
		setEnd(state, payload) {
			state.tracker.end = payload;
		},
		setProject(state, payload) {
			state.tracker.project = payload;
		},
		setClient(state, payload) {
			state.tracker.client = payload;
		},
		setDescription(state, payload) {
			state.tracker.description = payload;
		},
		setTask(state, payload) {
			state.tracker.task = payload;
		},
		setPage(state, page) {
			state.pagination.page = page;
		},
		setCount(state, count) {
			state.pagination.count = count;
		},
		setTime(state, time) {
			state.time = time;
		},
		loadTimes(state, times) {
			state.times = times;
		},
		loadReport(state, times) {
			state.report = times;
		},
		createTime(state, payload) {
			state.times.push(payload);
		},
		updateTime(state, payload) {
			let index = state.times.findIndex((time) => time._id == payload);
			let time = state.times[index];
			time.name = payload.name;
			time.status = payload.status;
		},
		deleteTime(state, payload) {
			let index = state.times.findIndex((time) => time._id == payload);
			state.times.splice(index, 1);
		},
	},
	actions: {
		startClock({ commit }, payload) {
			commit("setProject", payload.project);
			commit("setDescription", payload.description);
			commit("setClockActive", true);
			if (payload.task) {
				commit("setTask", payload.task);
			}
			commit("setSnapShot", DateTime.local().plus({ seconds: 1 }));
			commit("setClockStart", DateTime.local());
			commit("setStart", DateTime.local());
			commit(
				"setTick",
				setInterval(() => {
					commit("setSnapShot", DateTime.local());
				}, 1000)
			);
		},
		stopClock({ commit, dispatch, state, getters }) {
			commit("setClockActive", false);
			commit("setEnd", DateTime.local());
			clearInterval(state.tracker.tick);
			commit("setClockStart", DateTime.local());
			commit("setSnapShot", DateTime.local());
			dispatch("createTime", {
				startTime: state.tracker.start,
				endTime: state.tracker.end,
				project: state.tracker.project,
				client: getters.projectClient,
				description: state.tracker.description,
			});
			commit("setStart", null);
			commit("setEnd", null);
			commit("setProject", null);
			commit("setDescription", "");
			commit("setTask", null);
		},
		setPageNumber({ commit }, page) {
			commit("setPage", page);
		},
		loadTimes({ commit, dispatch, getters, state }) {
			commit("setLoading", true);
			HTTP.get(
				"/times" +
					"?page=" +
					state.pagination.page +
					"&limit=" +
					state.pagination.limit,
				{
					headers: {
						Authorization: "Bearer " + getters.user.token,
					},
				}
			)
				.then((response) => {
					commit("loadTimes", response.data.times);
					commit("setCount", response.data.count);
					commit("setLoading", false);
				})
				.catch((error) => {
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		loadProjectTimeReport({ commit, dispatch, getters }, payload) {
			commit("setLoading", true);
			HTTP.get(
				"/times" +
					"?start=" +
					payload.start +
					"&end=" +
					payload.end +
					"&project=" +
					payload.project,
				{
					headers: {
						Authorization: "Bearer " + getters.user.token,
					},
				}
			)
				.then((response) => {
					commit("loadReport", response.data.times);
					commit("setLoading", false);
				})
				.catch((error) => {
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		loadSummaryTimeReport({ commit, dispatch, getters }, payload) {
			commit("setLoading", true);
			HTTP.get(
				"/times" +
					"?start=" +
					payload.start +
					"&end=" +
					payload.end,
				{
					headers: {
						Authorization: "Bearer " + getters.user.token,
					},
				}
			)
				.then((response) => {
					commit("loadReport", response.data.times);
					commit("setLoading", false);
				})
				.catch((error) => {
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		createTime({ commit, dispatch, getters }, payload) {
			// eslint-disable-next-line no-console
			console.log(payload);
			commit("setLoading", true);
			HTTP.post("/times", payload, {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					// eslint-disable-next-line no-console
					console.log(response);
					dispatch("setResponse", response);
					dispatch("loadTimes");
					commit("setLoading", false);
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		updateTime({ commit, dispatch, getters }, payload) {
			commit("setLoading", true);
			HTTP.patch("/times/" + payload.id, payload, {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					dispatch("setResponse", response);
					commit("setLoading", false);
					dispatch("loadTimes");
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		deleteTime({ commit, dispatch, getters }, id) {
			commit("setLoading", true);
			HTTP.delete("/times/" + id, {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					dispatch("setResponse", response);
					commit("setLoading", false);
					dispatch("loadTimes");
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
	},
	getters: {
		time: (state) => {
			return state.time;
		},
		sortedTimes(state) {
			const items = [...state.times];
			// return items.reverse();
			return items;
		},
		sortedTimeReport(state) {
			const items = [...state.report];
			// return items.reverse();
			return items;
		},
		numPages(state) {
			return Math.ceil(state.pagination.count / state.pagination.limit);
		},
		projectClient(state, getters) {
			let index = getters.sortedProjects.findIndex(
				(project) => project._id == state.tracker.project
			);
			let project = getters.sortedProjects[index];
			return project.client._id;
		},
	},
};
