<template>
  <div>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          class="status-default"
          :class="{'status-active': status == 'draft'}"
          v-on="on"
          v-bind="attrs"
        >mdi-pencil-circle</v-icon>
      </template>
      <span>Draft</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          class="status-default"
          :class="{'status-active': status == 'sent'}"
          v-on="on"
          v-bind="attrs"
          @click="changeToSent()"
        >mdi-send-circle</v-icon>
      </template>
      <span>Invoice sent</span>
    </v-tooltip>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          class="status-default"
          :class="{'status-active': status == 'received'}"
          v-on="on"
          v-bind="attrs"
          @click="changeToReceived()"
        >mdi-check-circle</v-icon>
      </template>
      <span>Payment received</span>
    </v-tooltip>
    <!-- <span
      :class="{'sent': status == 'sent', 'received': status == 'received'}"
    >( {{statusMessage}} )</span>
    <v-btn @click="changeStatus('sent')" v-if="status == 'draft' && showButtons" small>Mark sent</v-btn>
    <v-btn @click="changeStatus('received')" v-if="status == 'sent' && showButtons" small>Mark received</v-btn>-->
  </div>
</template>
<script>
export default {
  props: ["invoice"],
  methods: {
    changeToSent() {
      if (this.status == "draft") {
        this.$store.dispatch("updateInvoice", {
          status: "sent",
          _id: this.id,
        });
      }
    },
    changeToReceived() {
      if (this.status == "sent") {
        this.$store.dispatch("updateInvoice", {
          status: "received",
          _id: this.id,
        });
      }
    },
  },
  computed: {
    status() {
      return this.invoice.status;
    },
    id() {
      return this.invoice._id;
    },
    statusMessage() {
      let statusText = this.status;
      if (this.status == "sent") {
        statusText = "Awaiting payment";
      }
      if (this.status == "received") {
        statusText = "Payment received";
      }
      return statusText;
    },
  },
};
</script>
<style scoped>
.status-default {
  color: lightgray;
}
.status-active {
  color: green;
}
</style>