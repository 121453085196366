<template>
  <v-container fluid>
    <v-card>
      <v-card-title
        ><h1>Dashboard</h1>
        <v-spacer></v-spacer>
        <span v-if="prefs.firstName">
          Howdy, {{ prefs.firstName }}!</span
        ></v-card-title
      >

      <v-card-text>
        <v-row>
          <v-col cols="12" xl="6" lg="6" sm="12" md="12"
            ><DashboardTodos :tasks="tasks"
          /></v-col>
          <v-col cols="12" xl="6" lg="6" sm="12" md="12">
            <InvoiceList
              v-if="invoices.length"
              :invoices="invoices"
              title="Outstanding invoices"
          /></v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import InvoiceList from "@/components/invoice/InvoiceList";
import DashboardTodos from "@/components/task/DashboardTodos";

export default {
  name: "home",
  components: { InvoiceList, DashboardTodos },
  methods: {},
  computed: {
    invoices() {
      return this.$store.getters.outstandingInvoices;
    },
    tasks() {
      return this.$store.getters.sortedTasks.filter((task) => {
        return task.priority === "todo";
      });
    },
    prefs() {
      return this.$store.getters.preferences;
    },
  },
  mounted() {
    this.$store.dispatch("loadOutstandingInvoices");
    this.$store.dispatch("clearInvoice");
    this.$store.dispatch("loadTasks");
    this.$store.dispatch("loadProjects");

},
};
</script>
