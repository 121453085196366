<template>
  <v-container width="400px">
      <div class="tracker-box" >
    <div class="tracker-grid">
      <div class="project-info">
        <v-autocomplete
          small solo dense
          :disabled="clockActive"
          v-model="select"
          label="Project"
          :items="projects"
          :item-text="(item) => item.name + ' - ' + item.client.name"
          item-value="_id"
        ></v-autocomplete>
      </div>
      <div class="description">
        <v-text-field solo dense
          small
          :disabled="clockActive"
          v-model="description"
          label="Description (optional)"
        ></v-text-field>
      </div>
      <div class="clock">{{ clock }}</div>
      <div class="controls">
        <v-btn
          v-show="clockActive"
          @click="stopClock"
          color="red"
          fab
          small
          light
        >
          <v-icon>mdi-stop</v-icon>
        </v-btn>
        <v-btn
          :disabled="!select"
          v-show="!clockActive"
          @click="startClock"
          color="success"
          fab
          small
          light
        >
          <v-icon>mdi-play</v-icon>
        </v-btn>
      </div>
      </div>
    </div>
 </v-container>
</template>

<script>
import { Duration } from "luxon";

export default {
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("loadProjects");
  },
  computed: {
    select: {
      get() {
        return this.$store.state.timeStore.tracker.project;
      },
      set(value) {
        this.$store.commit("setProject", value);
      },
    },
    description: {
      get() {
        return this.$store.state.timeStore.tracker.description;
      },
      set(value) {
        this.$store.commit("setDescription", value);
      },
    },
    projects() {
      return this.$store.getters.sortedProjects;
    },
    snapShot() {
      return this.$store.state.timeStore.tracker.snapShot;
    },
    clockStart() {
      return this.$store.state.timeStore.tracker.clockStart;
    },
    elapsed() {
      return this.snapShot.diff(this.clockStart).toObject();
    },
    clock() {
      return Duration.fromObject(this.elapsed).toFormat("h:mm:ss");
    },
    clockActive() {
      return this.$store.state.timeStore.tracker.clockActive;
    },
  },
  methods: {
    startClock() {
        let payload = {
            project: this.select,
            description: this.description
        }
      this.$store.dispatch("startClock", payload);
    },
    stopClock() {
      this.$store.dispatch("stopClock");
    },
  },
};
</script>
<style scoped>
.clock {
  font-size: 28px;
  font-family: monospace;
  /* color: white; */
}
.tracker-box {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  padding-left: 10px; 
  padding-right: 10px;
}
.tracker-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 130px 40px;
  /* padding: 5px; */
  grid-column-gap: 6px;
}
.tracker-grid > div {
  align-self: center;
}
.project-info,
.description {
  padding-top: 18px;
}
</style>