import axios from "axios";
import store from "../store";

// const apiPath = "http://localhost:3000/"; 
const apiPath = "https://frihed.ca/api/";

export const HTTP = axios.create({
	baseURL: apiPath, 
});

export const HTTP_SIMPLE = axios.create({
	baseURL: apiPath, 
});

// Response interceptor for API calls
HTTP.interceptors.response.use(
	(response) => {
		return response;
	},
	async function(error) {
		let originalRequest = error.config;
		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			await store.dispatch("tokenRefresh");
			originalRequest.headers.Authorization =
				"Bearer " + store.getters.user.token;
			return HTTP(originalRequest);
		}
		return Promise.reject(error);
	}
);

export const baseURL = apiPath;  
