var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"status-default",class:{'status-active': _vm.status == 'draft'}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil-circle")])]}}])},[_c('span',[_vm._v("Draft")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"status-default",class:{'status-active': _vm.status == 'sent'},on:{"click":function($event){return _vm.changeToSent()}}},'v-icon',attrs,false),on),[_vm._v("mdi-send-circle")])]}}])},[_c('span',[_vm._v("Invoice sent")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"status-default",class:{'status-active': _vm.status == 'received'},on:{"click":function($event){return _vm.changeToReceived()}}},'v-icon',attrs,false),on),[_vm._v("mdi-check-circle")])]}}])},[_c('span',[_vm._v("Payment received")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }