import { HTTP } from "@/components/http-common";
import router from "@/router/index";

export default {
	state: {
		editing: false,
		invoice: {},
		invoices: [],
		report: [],
		outstandingInvoices: [],
		pagination: {
			paginated: true,
			page: 1,
			limit: 20,
			count: 0,
		},
	},
	mutations: {
		setPage(state, page) {
			state.pagination.page = page;
		},
		setCount(state, count) {
			state.pagination.count = count;
		},
		setEditing(state, payload) {
			state.editing = payload;
		},
		setInvoice(state, invoice) {
			state.invoice = invoice;
		},
		loadInvoices(state, invoices) {
			state.invoices = invoices;
		},
		setOutstandingInvoices(state, invoices) {
			state.outstandingInvoices = invoices;
		},
		loadReport(state, invoices) {
			state.report = invoices;
		},
		createInvoice(state, payload) {
			state.invoices.push(payload);
		},
		addLineItem(state, payload) {
			state.invoice.items.push(payload);
		},
		deleteLineItem(state, payload) {
			let index = state.invoice.items.findIndex((item) => item.id == payload);
			state.invoice.items.splice(index, 1);
		},
		changeDate(state, payload) {
			state.invoice.date = payload;
		},
		// deleteInvoice(state, payload) {
		// 	let index = state.invoices.findIndex((invoice) => invoice._id == payload);
		// 	state.invoices.splice(index, 1);
		// },
	},
	actions: {
		setEditing({ commit }, payload) {
			commit("setEditing", payload);
		},
		addLineItem({ commit }, payload) {
			commit("addLineItem", payload);
		},
		deleteLineItem({ commit }, payload) {
			commit("deleteLineItem", payload);
		},
		changeDate({ commit }, payload) {
			commit("changeDate", payload);
		},
		loadInvoice({ commit, dispatch, getters }, id) {
			commit("setLoading", true);
			HTTP.get("/invoices/" + id, {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					commit("setInvoice", response.data.invoice);
					commit("setLoading", false);
				})
				.catch((error) => {
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		clearInvoice({ commit }) {
			commit("setInvoice", {});
		},
		loadInvoices({ commit, dispatch, getters, state }) {
			commit("setLoading", true);
			HTTP.get(
				"/invoices" +
					"?page=" +
					state.pagination.page +
					"&limit=" +
					state.pagination.limit,
				{
					headers: {
						Authorization: "Bearer " + getters.user.token,
					},
				}
			)
				.then((response) => {
					commit("loadInvoices", response.data.invoices);
					commit("setCount", response.data.count);
					commit("setLoading", false);
				})
				.catch((error) => {
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		loadOutstandingInvoices({ commit, dispatch, getters }) {
			commit("setLoading", true);
			HTTP.get("/invoices?status=sent", {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					commit("setOutstandingInvoices", response.data.invoices);
					commit("setLoading", false);
				})
				.catch((error) => {
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		loadInvoiceReport({ commit, dispatch, getters }, payload) {
			commit("setLoading", true);
			HTTP.get(
				"/invoices" + "?start=" + payload.start + "&end=" + payload.end,
				{
					headers: {
						Authorization: "Bearer " + getters.user.token,
					},
				}
			)
				.then((response) => {
					commit("loadReport", response.data.invoices);
					commit("setLoading", false);
				})
				.catch((error) => {
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		createInvoice({ commit, dispatch, getters }, payload) {
			// eslint-disable-next-line no-console
			console.log(payload);
			commit("setLoading", true);
			HTTP.post("/invoices", payload, {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					// eslint-disable-next-line no-console
					console.log(response);
					dispatch("setResponse", response);
					dispatch("loadInvoices");
					commit("setLoading", false);
					commit("setEditing", true);
					router.push("/invoices/" + response.data.createdInvoice._id);
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		updateInvoice({ commit, dispatch, getters }, payload) {
			commit("setLoading", true);
			let updateInfo = { ...payload, user: getters.user._id };

			HTTP.patch("/invoices/" + payload._id, updateInfo, {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					dispatch("setResponse", response);
					commit("setLoading", false);
					commit("setInvoice", response.data.updatedInvoice);
					dispatch("loadInvoices");
					dispatch("loadOutstandingInvoices");
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		deleteInvoice({ commit, dispatch, getters }, id) {
			commit("setLoading", true);
			HTTP.delete("/invoices/" + id, {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					dispatch("setResponse", response);
					commit("setLoading", false);
					dispatch("loadInvoices");
					router.push("/invoices/");
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
	},
	getters: {
		editing: (state) => {
			return state.editing;
		},
		invoice: (state) => {
			return state.invoice;
		},
		sortedInvoices(state) {
			const items = [...state.invoices];
			// return items.reverse();
			return items;
		},
		sortedInvoiceReport(state) {
			return state.report;
		},
		outstandingInvoices(state) {
			return state.outstandingInvoices;
		},
		invoicesNumPages(state) {
			return Math.ceil(state.pagination.count / state.pagination.limit);
		},
	},
};
