import { HTTP } from "@/components/http-common";

export default {
	state: {
		task: {},
		tasks: [],
	},
	mutations: {
		setTask(state, task) {
			state.task = task;
		},
		loadTasks(state, tasks) {
			state.tasks = tasks;
		},
		createTask(state, payload) {
			state.tasks.unshift(payload);
		},
		updateTask(state, payload) {
			let index = state.tasks.findIndex((task) => task._id == payload._id);
			let task = state.tasks[index];
			Object.entries(payload).forEach(([key, value]) => {
				task[key] = value;
			});
		},
		deleteTask(state, payload) {
			let index = state.tasks.findIndex((task) => task._id == payload);
			state.tasks.splice(index, 1);
		},
	},
	actions: {
		loadTasks({ commit, dispatch, getters }) {
			commit("setLoading", true);
			HTTP.get("/tasks", {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					commit("loadTasks", response.data.tasks);
					commit("setCount", response.data.count);
					commit("setLoading", false);
				})
				.catch((error) => {
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		createTask({ commit, dispatch, getters }, payload) {
			commit("setLoading", true);
			HTTP.post("/tasks", payload, {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					dispatch("setResponse", response);
					commit("createTask", response.data.createdTask);
					// dispatch("loadTasks");  I'm going to manage tasks locally
					commit("setLoading", false);
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		updateTask({ commit, dispatch, getters }, payload) {
			commit("setLoading", true);
			HTTP.patch("/tasks/" + payload._id, payload, {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					dispatch("setResponse", response);
					commit("updateTask", payload);
					commit("setLoading", false);
					// dispatch("loadTasks");
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		deleteTask({ commit, dispatch, getters }, id) {
			commit("setLoading", true);
			HTTP.delete("/tasks/" + id, {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
			})
				.then((response) => {
					dispatch("setResponse", response);
					commit("setLoading", false);
					commit("deleteTask", id);
				})
				.catch((error) => {
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
	},
	getters: {
		task: (state) => {
			return state.task;
		},
		sortedTasks(state) {
			const items = [...state.tasks];
			// return items.reverse();
			return items;
		},
	},
};
