import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies';
import vuetify from './plugins/vuetify';
import VueHtmlToPaper from "vue-html-to-paper";

const options = {
	name: "_blank",
	specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
	styles: ["https://frihed.ca/printstyles.css"],
};

Vue.use(VueHtmlToPaper, options);

Vue.use(VueCookies);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

