import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

function guard(to, from, next){
  if(store.state.userStore.user.token) {
      next(); // allow to enter route
  } else{
      next('/signin'); // go to '/signin';
  }
}

const routes = [
	{
		path: "/",
		name: "home",
		component: Home,
		beforeEnter: guard,
	},
	{
		path: "/about",
		name: "about",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/About.vue"),
	},
	{
		path: "/privacy",
		name: "privacy",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Privacy.vue"),
	},
	{
		path: "/terms",
		name: "terms",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Terms.vue"),
	},
	{
		path: "/signin",
		name: "signin",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "signin" */ "../components/user/SignInForm.vue"
			),
	},
	{
		path: "/signup",
		name: "signup",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "signup" */ "../components/user/SignUpForm.vue"
			),
	},
	{
		path: "/clients",
		name: "clients",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "project" */ "../components/client/Clients.vue"
			),
	},
	{
		path: "/clients/add",
		name: "client-add",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "project" */ "../components/client/AddClient.vue"
			),
	},
	{
		path: "/clients/:id",
		name: "client",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "project" */ "../components/client/Client.vue"
			),
	},
	{
		path: "/timetracker",
		name: "time-tracker",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "project" */ "../views/Tracker.vue"),
	},
	{
		path: "/timetracker/manual",
		name: "manual-time-entry",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "project" */ "../components/time/ManualTimeEntry.vue"
			),
	},
	{
		path: "/timetracker/reports",
		name: "create-report",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "project" */ "../components/time/CreateTimeReport.vue"
			),
	},
	{
		path: "/projects",
		name: "projects",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "project" */ "../components/project/Projects.vue"
			),
	},
	{
		path: "/projects/add",
		name: "project-add",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "project" */ "../components/project/AddProject.vue"
			),
	},
	{
		path: "/projects/:id",
		name: "project",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "project" */ "../components/project/Project.vue"
			),
	},
	{
		path: "/users",
		name: "users",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "users" */ "../components/user/Users.vue"),
	},
	{
		path: "/users/:id",
		name: "user",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "user" */ "../components/user/User.vue"),
	},
	{
		path: "/forgot",
		name: "forgot",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "user" */ "../components/user/ForgotPasswordForm.vue"
			),
	},
	{
		path: "/reset/:token",
		name: "reset",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "user" */ "../components/user/ResetPasswordForm.vue"
			),
	},
	{
		path: "/preferences",
		name: "Preferences",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "preferences" */ "../views/Preferences.vue"),
	},
	{
		path: "/invoices",
		name: "invoices",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "invoices" */ "../views/Invoices.vue"),
	},
	{
		path: "/invoices/:id",
		name: "invoice",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "invoices id" */ "../components/invoice/Invoice.vue"
			),
	},
	{
		path: "/invoice-report",
		name: "invoicereport",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "invoice reporting" */ "../components/invoice/CreateInvoiceReport.vue"
			),
	},
	{
		path: "/invoices/create",
		name: "invoice-create",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "project" */ "../components/invoice/CreateInvoice.vue"
			),
	},

	// expenses
	{
		path: "/expenses",
		name: "expenses",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "expenses" */ "../views/Expenses.vue"),
	},
	{
		path: "/expenses/:id",
		name: "expense",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "expensesid" */ "../components/expense/Expense.vue"
			),
	},
	{
		path: "/expense-report",
		name: "expensereport",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "expensereporting" */ "../components/expense/CreateExpenseReport.vue"
			),
	},
	{
		path: "/expenses/create",
		name: "expense-create",
		beforeEnter: guard,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "expense-create" */ "../components/expense/CreateExpense.vue"
			),
	},
];

const router = new VueRouter({
  routes
})

export default router
