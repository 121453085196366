<template>
  <v-card>
    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>
      <span v-if="subtitle" class="subtitle">Reporting: {{ subtitle }}</span>
    </v-card-title>
    <p class="text-center" v-if="paginated">
      Page: {{ page }} of {{ numPages }}
    </p>

    <div class="header item item-grid">
      <div>
        <strong>Date</strong>
      </div>
      <div>
        <strong>Client</strong>
      </div>
      <div>
        <strong>Invoice #</strong>
      </div>
      <div>
        <strong>Total</strong>
      </div>
      <div>
        <strong>Status</strong>
      </div>
      <div></div>
    </div>
    <div class="item-grid item" v-for="item in invoices" :key="item._id">
      <div>{{ getDate(item.date) }}</div>
      <div>{{ item.clientName }}</div>
      <div>{{ item.invoiceNumber }}</div>
      <div>
        <MoneyFormat :value="item.totalAmount / 100"></MoneyFormat>
      </div>
      <div>
        <StatusDisplay :invoice="item" />
      </div>
      <div>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" @click="openInvoice(item._id)"
              >mdi-file-document-edit-outline</v-icon
            >
          </template>
          <span>View or Edit</span>
        </v-tooltip>
      </div>
    </div>
    <div v-if="paginated == true" class="text-center">
      <v-pagination v-model="page" :length="numPages"></v-pagination>
    </div>
  </v-card>
</template>
<script>
import { DateTime } from "luxon";
import MoneyFormat from "vue-money-format";
import StatusDisplay from "@/components/invoice/StatusDisplay";

export default {
  props: ["invoices", "title", "subtitle", "paginated"],
  components: {
    MoneyFormat,
    StatusDisplay,
  },
  data() {
    return {
      page: 1,
    };
  },
  watch: {
    page() {
      this.$store.dispatch("setPageNumber", this.page);
      this.$store.dispatch("loadInvoices");
    },
  },
  methods: {
    deleteItem(id) {
      this.$store.dispatch("deleteTime", id);
    },
    getDate(dt) {
      let date = DateTime.fromISO(dt);
      return date.toFormat("LLLL dd, yyyy");
    },
    openInvoice(id) {
      this.$router.push("/invoices/" + id);
    },
  },
  computed: {
    numPages() {
      return this.$store.getters.invoicesNumPages;
    },
  },
  mounted() {
    this.$store.dispatch("clearInvoice");
  },
};
</script>
<style scoped>
.edit-icon {
  cursor: pointer;
}
.page {
  max-width: 1200px;
}
.item-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 50px;
  /* padding: 5px; */
  grid-column-gap: 6px;
}

.item {
  border-bottom: 1px solid #e7e7e7;
  padding: 16px;
}
.client-name {
  color: green;
  font-size: 12px;
  margin-left: 12px;
}
.description {
  font-style: italic;
  color: gray;
}
.subtitle {
  color: green;
  font-size: 12px;
  margin-left: 20px;
}
@media screen and (max-width: 800px) {
  .header {
    display: none;
  }
  .item-grid {
    grid-template-columns: 1fr;
  }
}
</style>