<template>
  <v-app id="inspire">
    <v-navigation-drawer
      app
      clipped
      color="green lighten-5"
      light
      v-model="drawer"
    >
      <v-list>
        <v-list-item to="/">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/clients">
          <v-list-item-action>
            <v-icon>mdi-account-supervisor</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Clients</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="user.token" to="/projects">
          <v-list-item-action>
            <v-icon>mdi-folder-multiple</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Projects</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="user.token" to="/timetracker">
          <v-list-item-action>
            <v-icon>mdi-calendar-clock</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Time Tracker</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="user.token" to="/invoices">
          <v-list-item-action>
            <v-icon>mdi-cash-plus</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Invoices</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/expenses">
          <v-list-item-action>
            <v-icon>mdi-cash-minus</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Expenses</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/preferences">
          <v-list-item-action>
            <v-icon>mdi-account-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Preferences</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="user.role == 'admin'" to="/users">
          <v-list-item-action>
            <v-icon>mdi-account-supervisor</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/signin">
          <v-list-item-action>
            <v-icon>mdi-account-arrow-left</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-if="!user.token">Sign In</v-list-item-title>
            <v-list-item-title v-if="user.token">Sign Out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="green" dense app dark clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>FriHed</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="user.token" @click="showTracker = !showTracker" x-small color="#AF4CAB"
        ><span v-html="trackerButtonText"></span>
        <img
          v-if="clockActive"
          class="hourglass"
          src="./assets/hourglass.gif"
          alt=""
        />
      </v-btn>
      <div class="gravatar-block">
        <Gravatar
          v-if="user.email"
          default-img="mp"
          size=32
          :alt="user.email"
          :email="user.email"
        />
      </div>
    </v-app-bar>

    <v-main>
      <Messenger />
      <Loading />
      <v-expand-transition duration=200
      >
      <TimeTracker v-if="user.token && showTracker" />

      </v-expand-transition>
      <router-view />
    </v-main>
    <v-footer color="#D0DCD1" class="justify-center" app>
      <span class="footer-area"
        >&copy; 2020, 2021, Sean Rasmussen. All rights reserved |
        <router-link to="terms">Terms of use</router-link> |
        <router-link to="privacy">Privacy policy</router-link>.
      </span>
    </v-footer>
  </v-app>
</template>

<script>
import Messenger from "@/components/Messenger";
import Loading from "@/components/DisplayLoading";
import TimeTracker from "@/components/time/TimeTracker";
import Gravatar from "vue-gravatar";

export default {
  props: {
    source: String,
  },
  data() {
    return {
      showTracker: false,
    };
  },
  components: {
    Messenger,
    Loading,
    TimeTracker,
    Gravatar,
  },
  computed: {
    trackerButtonText() {
      if (this.showTracker) {
        return "&#x25B2 Tracker";
      } else {
        return "&#x25BC Tracker";
      }
    },
    clockActive() {
      return this.$store.state.timeStore.tracker.clockActive;
    },
    drawer: {
      get() {
        return this.$store.state.sidebar;
      },
      set(val) {
        this.$store.dispatch("sidebar", val);
      },
    },
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.state.error;
    },
    response() {
      return this.$store.state.response;
    },
    signInText() {
      let text = "Sign In";
      if (this.user.token) {
        text = "Logout";
      }
      return text;
    },
  },
  methods: {},
  mounted() {
    if (!this.user.token) {
      this.$store.dispatch("sidebar", false);
    }
    const hrefString = window.location.href;

    if (!hrefString.includes("reset")) {
      this.$store.dispatch("autoSignIn");
    }
  },
};
</script>
<style>
.hourglass {
  width: 20px;
  height: 20px;
}
.footer-area {
  font-size: small;
}
.v-card__title h1 {
  color: #388e3c;
}
.v-card__title {
  color: lightslategray;
}
.gravatar-block img {
  border-radius: 16px;
  margin-left: 10px;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
