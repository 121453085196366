import { HTTP } from "@/components/http-common";

export default {
	state: {
		project: {},
		projects: [],
		sortAscending: true,
		sortBy: "name",
		showArchived: false
	},
	mutations: {
		setProject(state, project) {
			state.project = project;
		},
		loadProjects(state, projects) {
			state.projects = projects;
		},
		createProject(state, payload) {
			state.projects.push(payload);
		},
		updateProject(state, payload) {
			let index = state.projects.findIndex(project => project._id == payload);
			let project = state.projects[index];
			project.name = payload.name;
			project.status = payload.status;
		},
		deleteProject(state, payload) {
			let index = state.projects.findIndex(project => project._id == payload);
			state.projects.splice(index, 1);
		},
		setSortBy(state, payload) {
			state.sortBy = payload;
		},
		toggleSortOrder(state) {
			state.sortAscending = !state.sortAscending;
		},
		toggleArchivedProjects(state) {
			state.showArchived = !state.showArchived;
		}
	},
	actions: {
		toggleArchivedProjects({commit}) {
			commit("toggleArchivedProjects");
		},
		toggleSortOrder({ commit }) {
			commit("toggleSortOrder");
		},
		setSortBy({ commit }, payload) {
			commit("setSortBy", payload);
		},
		loadProjects({ commit, dispatch, getters }) {
			commit("setLoading", true);
			HTTP.get("/projects", {
				headers: {
					Authorization: "Bearer " + getters.user.token
				}
			})
				.then(response => {
					commit("loadProjects", response.data.projects);
					commit("setLoading", false);
				})
				.catch(error => {
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		createProject({ commit, dispatch, getters }, payload) {
			// eslint-disable-next-line no-console
			console.log(payload);
			commit("setLoading", true);
			HTTP.post(
				"/projects",
				{
					name: payload.name,
					client: payload.client,
					description: payload.description || ""
				},
				{
					headers: {
						Authorization: "Bearer " + getters.user.token
					}
				}
			)
				.then(response => {
					// eslint-disable-next-line no-console
					console.log(response);
					dispatch("setResponse", response);
					dispatch("loadProjects");
					commit("setLoading", false);
				})
				.catch(error => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		updateProject({ commit, dispatch, getters }, payload) {
			commit("setLoading", true);
			HTTP.patch("/projects/" + payload.id, payload, {
				headers: {
					Authorization: "Bearer " + getters.user.token
				}
			})
				.then(response => {
					dispatch("setResponse", response);
					commit("setLoading", false);
					dispatch("loadProjects");
				})
				.catch(error => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error);
					commit("setLoading", false);
				});
		},
		deleteProject({ commit, dispatch, getters }, id) {
			commit("setLoading", true);
			HTTP.delete("/projects/" + id, {
				headers: {
					Authorization: "Bearer " + getters.user.token
				}
			})
				.then(response => {
					dispatch("setResponse", response);
					commit("setLoading", false);
					dispatch("loadProjects");
				})
				.catch(error => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error);
					commit("setLoading", false);
				});
		}
	},
	getters: {
		project: state => {
			return state.project;
        },
		sortedProjects(state) {
			const items = [...state.projects].sort((a, b) => {
				if (a[state.sortBy].toLowerCase() > b[state.sortBy].toLowerCase()) {
					return 1;
				}
				if (a[state.sortBy].toLowerCase() < b[state.sortBy].toLowerCase()) {
					return -1;
				}
				return 0;
			});

			if (!state.sortAscending) {
				items.reverse();
			}
			if (!state.showArchived) {
				return items.filter(item => {
					return item.status !== 'archived';
				});
			} else {
				return items;
			}
		}
	}
};
