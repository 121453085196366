<template>
  <v-card>
    <v-card-title>Active To-Dos</v-card-title>
    <v-card-text v-if="projects.length">
      <div v-for="project in projectArray" :key="project._id">
          
        <div class="task-list">
          <h3>
            <router-link
              class="project-link"
              :to="`/projects/${project._id}`"
              >{{ project.name }}</router-link
            >
            <span class="client-name">({{ project.client.name }})</span>
          </h3>

          <div
            class="task-row"
            v-for="task in filterTasksByProject(project._id)"
            :key="task._id"
          >
            <Task :task="task"></Task>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import Task from "@/components/task/Task";

export default {
  props: ["tasks", "project"],
  components: { Task },
  computed: {
    projects() {
      return this.$store.state.projectStore.projects;
    },
    projectIds() {
      let projectIds = [];
      this.tasks.forEach((task) => {
        projectIds.push(task.project);
      });
      return projectIds.filter(this.onlyUnique);
    },
    projectArray() {
      let objArr = [];
      this.projectIds.forEach((id) => {
        objArr.push(this.getProject(id));
      });
      return objArr.sort((a, b) => {
        let nameA = a.name.toLowerCase();
        let nameB = b.name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    },
  },
  methods: {
    getProject(p) {
      return this.projects.find((project) => {
        return project._id === p;
      });
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    filterTasksByProject(project) {
      return this.tasks.filter((task) => {
        return task.project === project;
      });
    },
  },
};
</script>
<style scoped>
.task-list {
  padding: 10px 0px;
}
.client-name {
  font-style: italic;
  margin-left: 5px;
  font-weight: lighter;
}
.project-link {
  text-decoration: none;
  color: #AF4CAB;
}
</style>

