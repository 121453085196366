<template>
  <div v-if="isLoading">
    <v-progress-linear
      color="green"
      indeterminate
      rounded
      height="6"
    ></v-progress-linear>
  </div>
</template>
 
<script>
// Import component
// import Loading from 'vue-loading-overlay';
// Import stylesheet
// import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  data() {
    return {
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.loading;
    },
  },
  components: {
  },
  methods: {},
};
</script> 