<template>
  <div @dblclick="editTask" class="task-row" :class="{ 'fade-out': complete }">
    <v-icon @click="toggleComplete" v-if="!complete" style="cursor: pointer"
      >mdi-checkbox-blank-outline</v-icon
    >
    <v-icon @click="toggleComplete" v-if="complete" style="cursor: pointer"
      >mdi-checkbox-marked-outline</v-icon
    >
    <span
      v-if="!editingTask"
      class="task-description"
      :class="{ complete: complete }"
      >{{ task.description }}
    </span>
    <span class="inline-field" v-if="editingTask">
      <v-text-field
        dense
        @keydown.enter="updateTaskDescription"
        v-model="task.description"
        autofocus
      ></v-text-field
    ></span>
    <span class="tracking-indicator" v-if="taskBeingTracked">
      <img class="hourglass" src="../../assets/hourglass.gif" alt="" />
    </span>
    <span class="spacer"></span>
    <span class="actions>">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="mr-5"
            :color="trackerIconColor"
            v-on="on"
            v-bind="attrs"
            @click="startTimer()"
            >mdi-clock-start</v-icon
          >
        </template>
        <span>Start clock on this task</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="mr-5"
            :color="todoIconColor"
            v-on="on"
            v-bind="attrs"
            @click="toggleTodo()"
            >mdi-reminder</v-icon
          >
        </template>
        <span>Set as a 'todo' task</span>
      </v-tooltip>
    </span>
  </div>
</template>
<script>
export default {
  props: ["task", "project"],
  watch: {
    complete() {
      this.handleCompleteStatus();
    },
  },
  data() {
    return {
      complete: false,
      timerId: null,
      editingTask: false,
    };
  },
  computed: {
    taskBeingTracked() {
      return this.task._id == this.$store.state.timeStore.tracker.task;
    },
    clockActive() {
      return this.$store.state.timeStore.tracker.clockActive;
    },
    todoIconColor() {
      let colorString = "grey lighten-1";
      if (this.task.priority == "todo") {
        colorString = "black";
      }
      return colorString;
    },
    trackerIconColor() {
      let colorString = "grey lighten-1";

      if (this.taskBeingTracked) {
        colorString = "red";
      }
      return colorString;
    },
    waitingIconColor() {
      let colorString = "grey lighten-1";
      if (this.task.priority == "waiting") {
        colorString = "yellow darken-3";
      }
      return colorString;
    },
  },
  methods: {
    updateTaskDescription() {
      // eslint-disable-next-line no-console
      console.log(this.task);
      this.$store.dispatch("updateTask", {
        _id: this.task._id,
        description: this.task.description,
      });
      this.editTask();
    },
    editTask() {
      this.editingTask = !this.editingTask;
    },
    startTimer() {
      if (this.clockActive) {
        return;
      }
      this.$store.dispatch("startClock", {
        project: this.task.project,
        description: this.task.description,
        task: this.task._id,
      });
    },
    toggleTodo() {
      if (this.task.priority == "normal") {
        let payload = {
          _id: this.task._id,
          priority: "todo",
        };
        this.$store.dispatch("updateTask", payload);
      } else {
        let payload = {
          _id: this.task._id,
          priority: "normal",
        };
        this.$store.dispatch("updateTask", payload);
      }
    },
    toggleComplete() {
      this.complete = !this.complete;
    },
    handleCompleteStatus() {
      if (this.complete) {
        this.timerId = setTimeout(
          () => this.$store.dispatch("deleteTask", this.task._id),
          3000
        );
      }
      if (!this.complete) {
        // eslint-disable-next-line no-console
        console.log("clear timeout: " + this.timerId);
        clearTimeout(this.timerId);
      }
    },
  },
};
</script>

<style scoped>
.tracking-indicator {
  color: red;
  font-weight: bold;
  padding-left: 10px;
}
.task-row {
  display: flex;
  padding: 5px 0px;
  border-bottom: 1px solid #e7e7e7;
  align-items: center;
}
.task-description {
  /* font-size: 20px; */
  /* font-weight: bold; */
  /* color: gray; */
  padding-left: 15px;
}
.complete {
  text-decoration: line-through;
}
.fade-out {
  animation: fadeOut ease 5s;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.icon-button {
  cursor: pointer;
}
.inline-field {
  padding-left: 10px;
}
</style>

