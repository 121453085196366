import Vue from "vue";
import Vuex from "vuex";
import clientStore from "@/store/modules/clientStore.js";
import userStore from "@/store/modules/userStore.js";
import projectStore from "@/store/modules/projectStore";
import timeStore from "@/store/modules/timeStore";
import taskStore from "@/store/modules/taskStore";
import invoiceStore from "@/store/modules/invoiceStore";
import expenseStore from "@/store/modules/expenseStore";
import preferencesStore from "@/store/modules/preferencesStore";
import fileDownload from "js-file-download";
import { HTTP } from "@/components/http-common";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
        response: { },
        error: { },
		loading: false,
		sidebar: false,
	},
	mutations: {
		setLoading(state, status) {
			state.loading = status;
		},
		setError(state, error) {
			state.error = error;
		},
		setResponse(state, response) {
			state.response = response;
		},
		popError(state) {
			state.error = {};
		},
		popResponse(state) {
			state.response = {};
		},
		sidebar(state, val) {
			state.sidebar = val;
		},
	},
	actions: {
		downloadCSV({ getters, dispatch }, payload) {
			// eslint-disable-next-line no-console
			console.log("downloadCSV start");
			HTTP.post("/files/csv", payload, {
				headers: {
					Authorization: "Bearer " + getters.user.token,
				},
				responseType: "blob",
			})
				.then((res) => {
					fileDownload(res.data, payload.fileName);
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.log(error);
					dispatch("setError", error.response);
				});
		},
		setLoading({ commit }, payload) {
			commit("setLoading", payload);
		},
		sidebar({ commit, getters }, val) {
			if (getters.user.token) {
				commit("sidebar", val);
			} else {
				commit("sidebar", false);
			}
		},
		setError({ commit }, payload) {
			commit("setError", payload);
			setTimeout(() => {
				commit("popError");
			}, 5000);
		},
		setResponse({ commit }, payload) {
			commit("setResponse", payload);
			setTimeout(() => {
				commit("popResponse");
			}, 5000);
		},
		popError({ commit }) {
			commit("popError");
		},
		popResponse({ commit }) {
			commit("popResponse");
		},
	},
	modules: {
		clientStore,
		userStore,
		projectStore,
		timeStore,
        invoiceStore,
        expenseStore,
		preferencesStore,
		taskStore,
	},
});
