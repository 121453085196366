<template>
  <v-snackbar v-model="snackbar">
    <span v-if="error.data">{{ error.data.message }}</span>
    <span v-if="response.data">{{ response.data.message }}</span>
    <template v-slot:action="{ attrs }">
      <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    snackbar() {
      let showSnack = true;
      if (this.error.data || this.response.data) {
        showSnack = true;
      } else {
        showSnack = false;
      }
      return showSnack;
    },
    error() {
      return this.$store.state.error;
    },
    response() {
      return this.$store.state.response;
    },
  },
};
</script>
<style>
</style>
